@import 'bootstrap/_bootstrap.scss';

$color:#a17f6c;
$color1: #ee8f3b;

ul,
ol,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    color: #000;
    transition: color .20s linear, background-color .20s linear, border-color .20s linear;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
}

a:hover {
    color: $color;
}

h1,
h2,
h3,
h4,
h5 {
    margin-top: 0;
    padding-top: 0;
    color: #000;
}

body {
    font: 16px/1.6 'Microsoft YaHei', 'Heiti SC', SimHei, 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', sans-serif;
    color: #787878;
    background: #fff;
    -webkit-font-smoothing: antialiased;
}

// sass mix
@mixin img2x($file, $type, $width, $height) {
    background-image: url($file + '.' + $type);
    background-repeat: no-repeat;
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        & {
            background-image: url($file + '@2x.' + $type);
            -webkit-background-size: $width $height;
            -moz-background-size: $width $height;
            -o-background-size: $width $height;
            background-size: $width $height;
            background-repeat: no-repeat;
        }
    }
}

@mixin text-overflow($line: 1, $substract: 0) {
    overflow: hidden;
    @if $line==1 {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100% - $substract;
    }
    @else {
        display: -webkit-box;
        -webkit-line-clamp: $line;
        -webkit-box-orient: vertical;
    }
}

@mixin center($direction: both) {
    position: absolute;
    @if $direction==both {
        top: 50%;
        left: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
    }
    @else if $direction==horizontal {
        left: 50%;
        -webkit-transform: translate3d(-50%, 0, 0);
        transform: translate3d(-50%, 0, 0);
    }
    @else if $direction==vertical {
        top: 50%;
        -webkit-transform: translate3d(0, -50%, 0);
        transform: translate3d(0, -50%, 0);
    }
}

.btn-theme {
    background: $color;
    color: #fff;
    &:hover,
    &:focus {
        color: #fff;
        background: darken($color, 10%);
    }
}

.btn-buy {
    background: transparent;
    color: $color;
    border: 1px solid $color;
    font-size: 16px;
    &:hover,
    &:focus {
        color: #fff;
        background: $color;
    }
}

.icon {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: middle;
    background-position: center center;
    background-repeat: no-repeat;
}

.arrow-l {
    width: 36px;
    height: 36px;
    background-image: url(../images/arrow-l.png);
}

.arrow-r {
    width: 36px;
    height: 36px;
    background-image: url(../images/arrow-r.png);
}

.icon-more {
    width: 36px;
    height: 36px;
    @include img2x('../images/icon-more',png,36px,36px)
}

.icon-address {
    width: 18px;
    height: 18px;
    background-image: url(../images/icon-address.png);
}

.icon-phone {
    width: 18px;
    height: 18px;
    background-image: url(../images/icon-phone.png);
}

.icon-tmall {
    width: 20px;
    height: 20px;
    @include img2x('../images/icon-tmall',png,20px,20px)
}

.icon-jd {
    width: 20px;
    height: 20px;
    @include img2x('../images/icon-jd',png,20px,20px)
}

.dropdown-menu {
    min-width: 80px;
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.21);
    &>li>a {
        &:hover,&:focus {
            color: #fff;
            background-color: $color;
        }
    }
}

.caret {
    width: 10px;
    height: 6px;
    border: none;
    @include img2x('../images/arrow-down',png,10px,6px)
}

.video-js {
    &:hover .vjs-big-play-button{
        background-color: rgba(0, 0, 0, 0.8);
    }
    .vjs-big-play-button {
        background-color: #000;
        border: none;
        border-radius: 3px;
        &:focus {
            background-color: rgba(0, 0, 0, 0.8);
        }
    }
    .vjs-control-bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .vjs-poster {
        background-size: cover;
    }
    button {
        &:focus {
            outline: none;
        }
    }
}

.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
}

.banner-store,.section-two {
    .vjs_video_3-dimensions.vjs-fluid {
        padding-top: 36%;
    }
}


// Header
.nav-top {
    height: 38px;
    line-height: 38px;
    color: #fff;
    background: $color;
    .stock-code {
        float: left;
        height: 38px;
        overflow: hidden;
    }
    .top-list {
        float: right;
        font-size: 14px;
        &>li {
            float: left;
            margin: 0 15px;
            &:last-child {
                margin-right: 10px;
            }
            &>a {
                display: block;
                color: #fff;
            }
        }
    }
}

#weixin-qr {
    position: relative;
    &:hover {
        .wx-qr {
            display: block;
        }
    }
    .wx-qr {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        width: 90px;
        height: 90px;
        margin-left: -20px;
        padding: 5px;
        background-color: #fff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.21);
    }
}

.stock-code {
    font-size: 18px;
    span {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: bottom;
        font-size: 22px;
        line-height: 38px;
    }
}

.navbar-brand.logo {
    width: 170px;
    height: 60px;
    margin: 16px 0 0;
    @include img2x('../images/logo',png,170px,60px)
    text-indent: -9999px;
    overflow: hidden;
}

.navbar.navbar-theme {
    min-height: 92px;
    margin-bottom: 0;
    background: #fff;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #d2d2d2;
    .navbar-nav {
        margin: 0;
        &>li {
            padding: 0 20px;
            &:hover {
                a {
                    color: $color;
                    background: none;
                    &:after {
                        height: 2px;
                        opacity: 1;
                        filter: alpha(opacity=100);
                    }
                }
                .sub-wrap {
                    display: block;
                }
            }
            &.active {
                a {
                    color: $color;
                    background: none;
                    &:after {
                        height: 2px;
                        opacity: 1;
                        filter: alpha(opacity=100);
                    }
                }
            }
            &:last-child {
                padding-right: 0;
            }
            &>a {
                position: relative;
                height: 91px;
                line-height: 91px;
                padding: 0;
                color: #000;
                background: none;
                font-size: 18px;
                font-weight: lighter;
                text-align: center;
                &:after {
                    height: 0;
                    opacity: 0;
                    filter: alpha(opacity=0);
                    position: absolute;
                    bottom: 16px;
                    left: 0;
                    content: "";
                    width: 100%;
                    background: $color;
                    transition: .3s;
                }
                &:hover {
                    color: $color;
                    background: none;
                    &:after {
                        height: 2px;
                        opacity: 1;
                        filter: alpha(opacity=100);
                    }
                }
            }
        }
    }
}

.navbar-theme {
    .navbar-toggle {
        margin-top: 14px;
        border: none;
        .icon-bar {
            background-color: $color;
        }
    }
}
.sub-wrap {
    display: none;
    position: absolute;
    top: 92px;
    left: -57px;
    z-index: 999;
    width: 520px;
    height: 130px;
    padding: 40px 0 0;
    &.store-sub {
        left: 0;
        width: 200px;
    }
}

.sub-bg {
    display: none;
    position: absolute;
    top: 91px;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 140px;
    background: #fff;
    border-top: 1px solid #d2d2d2;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.05);
}

.open {
    .sub-bg {
        display: block;
    }
}

.sub-menu {
    @extend .clearfix;
    position: relative;
    z-index: 3;
    li {
        float: left;
        margin-right: 50px;
        margin-bottom: 10px;
        &:first-child a {
            min-width: 80px;
        }
        a {
            display: block;
            width: auto;
            color: #787878;
            &:hover {
                color: $color;
                text-decoration: underline;
            }
            i {
                margin-right: 10px;
            }
        }
    }
}

.store-sub {
    .sub-menu li {
        margin-right: 0;
        a {
            width: auto;
        }
    }
}


// News
.head-title {
    position: relative;
    margin-bottom: 40px;
    padding: 47px 0 19px 0;
    &:before,&:after {
        position: absolute;
        left: 0;
        content: '';
        width: 100px;
        height: 1px;
        background-color: $color;
    }
    &:before {
        top: 0;
    }
    &:after {
        bottom: 0;
    }
    h2 {
        @include text-overflow(1);
        margin: 0 0 19px;
        color: $color;
        font-size: 36px;
        font-weight: bold;
        line-height: 1.2;
    }
    h4 {
        @include text-overflow(1);
        margin: 0;
        color: $color;
        font-size: 18px;
        line-height: 1;
    }
    &.right {
        text-align: right;
        &:before,&:after {
            left: auto;
            right: 0;
        }
    }
}

.box-title {
    max-width: 310px;
    max-height: 310px;
    padding: 137px 34px 34px;
    border: 4px solid $color;
}

.page-wrap {
    padding: 100px 0 110px;
    background: url(../images/m-bg.png) center top -150px no-repeat;
}

.news-list {
    @extend .clearfix;
    margin: 0 -30px;
    .item {
        float: left;
        display: block;
        width: 50%;
        margin-bottom: 60px;
        padding: 0 30px;
        &:hover {
            h4 {
                color: $color;
            }
            .intro:after {
                background-color: $color;
            }
        }
    }
    .time {
        margin-bottom: 19px;
        font-size: 18px;
        line-height: 1;
        color: #969696;
    }
    .pic {
        height: 200px;
        margin-bottom: 20px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    h4 {
        @include text-overflow(2);
        height: 64px;
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 32px;
        transition: .3s;
    }
    .intro {
        position: relative;
        font-size: 16px;
        color: #787878;
        &:after {
            position: absolute;
            left: 0;
            top: 84px;
            content: '';
            width: 100px;
            height: 1px;
            background-color: #000;
            transition: .3s;
        }
        p {
            height: 100px;
            line-height: 25px;
            @include text-overflow(4);
        }
    }
}

.page-navi {
    text-align: center;
    font-size: 0;
    li {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: middle;
        &.active {
            a {
                color: $color;
            }
        }
        a {
            display: block;
            padding: 5px 10px;
            color: #aaa;
            font-size: 16px;
            &:hover {
                color: $color;
            }
        }
    }
}

.footer {
    background-color: #ebebeb;
    .footer-cont {
        @extend .clearfix;
        padding: 50px 0 30px;
        border-bottom: 1px solid #d4d4d4;
    }
    .footer-logo {
        float: left;
        img {
            width: 195px;
            height: auto;
        }
    }
    .tel {
        margin-top: 24px;
        padding-left: 35px;
        font-size: 24px;
        color: $color;
        background: url(../images/icon-tel.png) 0 center no-repeat;
    }
    .footer-qrcode {
        float: right;
        text-align: center;
        img {
            width: 128px;
            height: auto;
            margin-bottom: 10px;
        }
        p {
            margin: 0;
            font-size: 16px;
            color: #787878;
        }
    }
    .stock-code {
        margin-top: 19px;
        font-size: 20px;
        color: #000;
        line-height: 24px;
        span {
            font-size: 24px;
            line-height: 24px;
            vertical-align: text-bottom;
        }
    }
}
.footer-link {
    float: left;
    margin-left: 100px;
    h4 {
        margin-bottom: 16px;
        font-size: 18px;
    }
    li {
        line-height: 25px;
        a {
            display: block;
            color: #787878;
            font-size: 14px;
            &:hover {
                color: $color;
            }
        }
    }
}
.copyright {
    height: 50px;
    line-height: 50px;
    font-size: 12px;
    color: #000;
    text-align: center;
}


// News Article
.news-title {
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 20px;
    &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        width: 200px;
        height: 1px;
        background-color: #000;
        transition: .3s;
    }
    h2 {
        margin-bottom: 19px;
        font-size: 30px;
        font-weight: bold;
        line-height: 1;
    }
    .time {
        font-size: 18px;
        line-height: 1;
        color: #969696;
    }
}

.news-content {
    color: #787878;
    line-height: 1.8;
    font-size: 16px;
    .img-intro {
        display: block;
        margin-top: 20px;
        padding-bottom: 10px;
        color: #a1a1a1;
        line-height: 1;
    }
    .alignleft {
        float: left;
    }
    .alignright {
        float: right;
    }
    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    p {
        margin-bottom: 30px;
        word-break: break-all;
    }
    ul,ol {
        padding-left: 20px;
    }
    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
    video {
        max-width: 100%;
        height: auto;
    }
}

.news-footer {
    color: #787878;
    line-height: 1.8;
    font-size: 16px;
    .title {
        margin-bottom: 4px;
        font-size: 18px;
        font-weight: bold;
        color: $color;
        line-height: 1.8;
        text-decoration: underline;
    }
    p {
        margin-bottom: 15px;
    }
}

.multi-bg {
    padding: 100px 0 40px;
    background: url(../images/bg.png) center top -90px no-repeat;
}


// Product
.product-list {
    @extend .clearfix;
    margin: 0 -30px;
    .item {
        float: left;
        display: block;
        width: 33.3333%;
        margin-bottom: 50px;
        padding: 0 30px;
        &:hover {
            .more {
                opacity: 1;
                filter: alpha(opacity=100);
            }
            .title, .series {
                color: $color;
            }
        }
    }
    .pic {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        margin-bottom: 30px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .title {
        @include text-overflow(1);
        margin-bottom: 10px;
        font-size: 14px;
        color: #aaa;
        line-height: 1;
        transition: .3s;
    }
    .series {
        font-size: 24px;
        font-weight: bold;
        color: #000;
        transition: .3s;
    }
    .more {
        position: absolute;
        width: 100%;
        height: 100%;
        padding-top: 45%;
        color: #fff;
        background-color: rgba(161, 127, 108, 0.8);
        text-align: center;
        opacity: 0;
        filter: alpha(opacity=0);
        transition: .3s;
        i {
            margin-bottom: 9px;
        }
    }
}


@keyframes hover-color {
    from {
        border-color: #c0c0c0;
    }
    to {
        border-color: $color;
    }
}

.magic-radio,
.magic-checkbox {
    position: absolute;
    display: none;
}

.magic-radio[disabled],
.magic-checkbox[disabled] {
    cursor: not-allowed;
}

.magic-radio+label,
.magic-checkbox+label {
    position: relative;
    display: block;
    padding-left: 22px;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
    vertical-align: middle;
}

// .magic-radio+label:hover:before,
// .magic-checkbox+label:hover:before {
//     animation-duration: 0.4s;
//     animation-fill-mode: both;
//     animation-name: hover-color;
// }

.magic-radio+label:before,
.magic-checkbox+label:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 14px;
    height: 14px;
    content: '';
    border: 1px solid $color;
}

.magic-radio+label:after,
.magic-checkbox+label:after {
    position: absolute;
    display: none;
    content: '';
}

.magic-radio[disabled]+label,
.magic-checkbox[disabled]+label {
    cursor: not-allowed;
    color: #e4e4e4;
}

.magic-radio[disabled]+label:hover,
.magic-radio[disabled]+label:before,
.magic-radio[disabled]+label:after,
.magic-checkbox[disabled]+label:hover,
.magic-checkbox[disabled]+label:before,
.magic-checkbox[disabled]+label:after {
    cursor: not-allowed;
}

.magic-radio[disabled]+label:hover:before,
.magic-checkbox[disabled]+label:hover:before {
    border: 1px solid #e4e4e4;
    animation-name: none;
}

.magic-radio[disabled]+label:before,
.magic-checkbox[disabled]+label:before {
    border-color: #e4e4e4;
}

.magic-radio:checked+label:before,
.magic-checkbox:checked+label:before {
    animation-name: none;
}

.magic-radio:checked+label:after,
.magic-checkbox:checked+label:after {
    display: block;
}

.magic-radio+label:before {
    // border-radius: 50%;
}

.magic-radio+label:after {
    top: 7px;
    left: 7px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $color;
}

.magic-radio:checked+label:before {
    border: 1px solid $color;
}

.magic-radio:checked[disabled]+label:before {
    border: 1px solid #c9e2f9;
}

.magic-radio:checked[disabled]+label:after {
    background: $color;
}

.magic-checkbox+label:after {
    top: 0;
    left: 5px;
    box-sizing: border-box;
    width: 5px;
    height: 10px;
    transform: rotate(45deg);
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0;
}

.magic-checkbox:checked+label:before {
    border: $color;
    background: $color;
}

.magic-checkbox:checked+label {
    color: $color;
}

.magic-checkbox:checked[disabled]+label:before {
    border: #c9e2f9;
    background: #c9e2f9;
}


.product-filter {
    margin-bottom: 30px;
    li {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: middle;
        margin-right: 20px;
    }
}


// Honor
.sidebar {
    position: relative;
}

.page-nav {
    position: relative;
    padding-top: 42px;
    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 220px;
        height: 1px;
        background-color: #d2d2d2;
    }
    li {
        &.active {
            a {
                color: #000;
                font-weight: bold;
                text-decoration: underline $color;
            }
        }
        a {
            display: block;
            padding: 10px 0;
            font-size: 16px;
            color: #979797;
            line-height: 1;
            &:hover {
                color: #000;
                font-weight: bold;
                text-decoration: underline $color;
            }
        }
    }
}

.honor-head {
    @extend .clearfix;
    margin-bottom: 100px;
    .img {
        float: left;
        margin-right: 100px;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .intro {
        overflow: hidden;
        font-size: 16px;
        line-height: 1.7;
    }
}

.honor-pics {
    @extend .clearfix;
    margin: 0 -20px;
    li {
        float: left;
        width: 25%;
        margin-bottom: 100px;
        padding: 0 20px;
        img {
            max-width: 100%;
            height: auto;
            padding: 6px;
            border: 4px solid $color;
        }
    }
}


// Contact
.banner {
    img {
        width: 100%;
        height: auto;
    }
    video {
        width: 100%;
        height: auto;
        background-color: #000;
    }
}
.contact-method {
    margin-bottom: 60px;
    text-align: center;
    .icon-pic {
        margin-bottom: 70px;
    }
    .weixin-qr {
        img {
            width: 160px;
            border: 5px solid #fff;
        }
    }
    h5 {
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 1;
    }
    p {
        margin-bottom: 0;
        font-size: 18px;
        color: $color;
    }
}

.team-pics {
    max-width: 1050px;
    margin-bottom: 70px;
    img {
        max-width: 100%;
        height: auto;
    }
}

.store-slide {
    position: relative;
    padding: 0 60px;
    .swiper-button-prev, .swiper-button-next {
        position: absolute;
        top: auto;
        bottom: 50px;
        width: 35px;
        height: 54px;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .swiper-button-prev {
        left: 0;
        background-image: url(../images/icon-left.png);
    }
    .swiper-button-next {
        right: 0;
        background-image: url(../images/icon-right.png);
    }
}

.contact {
    .page-nav {
        padding-top: 0;
        &:before {
            display: none;
        }
    }
    .content {
        font-size: 16px;
        line-height: 1.8;
        h5 {
            font-size: 16px;
        }
    }
    .recruit-footer {
        margin-top: 60px;
        padding: 10px 0;
        color: #000;
        border-top: 1px solid #d2d2d2;
        line-height: 1;
        p {
            margin-bottom: 18px;
        }
    }
}

.contact-video {
    margin-bottom: 60px;
    video {
        width: 100%;
        height: auto;
    }
}

.contact-team {
    position: relative;
}

.store {
    padding: 110px 0 0;
    background: url(../images/bg.png) center top -1000px no-repeat;
}

.store-item {
    @include clearfix();
    margin-bottom: 70px;
}

.store-slide {
    position: relative;
    float: left;
    width: 60%;
    height: 450px;
    margin-bottom: 30px;
    .swiper-slide {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .gallery-top {
        height: 72.89%;
        width: 100%;
    }
    .contact-team {
        height: 23.58%;
    }
    .gallery-thumbs {
        height: 100%;
        box-sizing: border-box;
        margin: 10px 0;
        .swiper-wrapper{
            margin-left: -34.33%;
        }
    }
    .gallery-thumbs .swiper-slide {
        width: 32.33%;
        height: 100%;
        margin-right: 1%;
        margin-left: 1%;
        opacity: 0.4;
    }
    .gallery-thumbs .swiper-slide-active {
        opacity: 1;
    }
}

.store-intro {
    float: left;
    width: 40%;
    padding-left: 20px;
    font-size: 16px;
    .intro-head {
        position: relative;
        margin-bottom: 20px;
        padding-bottom: 40px;
        color: $color;
        font-size: 18px;
        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: 100px;
            height: 1px;
            background-color: $color;
            transition: .3s;
        }
        h2 {
            margin-bottom: 20px;
            line-height: 1;
            font-size: 30px;
            font-weight: bold;
            color: $color;
        }
        li {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            vertical-align: middle;
            margin-right: 36px;
            line-height: 18px;
            i {
                margin-right: 4px;
                vertical-align: top;
            }
        }
    }
    .address-qr {
        width: 120px;
        margin-top: 50px;
        text-align: center;
        img {
            margin-bottom: 10px;
        }
    }
}


// Team
.team-wrap {
    background: url(../images/bg.png) center top no-repeat;
    .page-nav:before {
        display: none;
    }
}
.team-head {
    margin-bottom: 60px;
}

.team-list {
    .item {
        @extend .clearfix;
        margin-bottom: 100px;
        .avt {
            float: left;
            width: 25.42%;
            text-align: center;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        .intro {
            float: right;
            width: 66.1%;
        }
    }
    .intro-head {
        position: relative;
        margin-bottom: 40px;
        padding-bottom: 1px;
        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100px;
            height: 1px;
            background: #000;
        }
        .name {
            margin-bottom: 18px;
            color: $color;
            font-size: 36px;
            line-height: 1;
        }
        h5 {
            margin-bottom: 20px;
            font-size: 23px;
        }
    }
    .item1 {
        .intro-head {
            margin-bottom: 10px;
        }
    }
    .video-link {
        margin-bottom: 20px;
    }
    .item-right {
        text-align: right;
        .avt {
            float: right;
            margin-right: 0;
        }
        .intro {
            float: left;
        }
        .intro-head {
            &:after {
                left: auto;
                right: 0;
            }
        }
    }
}

.design-pics {
    @extend .clearfix;
    margin: 50px -30px 0;
    li {
        float: left;
        width: 25%;
        margin-bottom: 50px;
        padding: 0 30px;
        img {
            max-width: 100%;
            height: auto;
        }
    }
}


// Public
.public-banner {
    margin-bottom: 100px;
    img {
        width: 100%;
        height: auto;
    }
}

.public-content {
    .img1 {
        margin-bottom: 40px;
    }
}

.public-msci {
    margin-bottom: 107px;
    .pics {
        margin-bottom: 50px;
        img:last-child {
            float: right;
            width: 55%;
        }
        img {
            max-width: 100%;
            height: auto;
        }
        .box-title {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            vertical-align: middle;
            width: 310px;
        }
    }
    .text {
        max-width: 750px;
    }
}

.public3 {
    .pics {
        @extend .clearfix;
        margin-bottom: 50px;
    }
    .pic1 {
        float: left;
        margin-right: 98px;
    }
    .pic2 {
        float: left;
        width: 27.27%;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .intro {
        float: right;
        width: 36.36%;
        line-height: 1.6;
        img {
            max-width: 100%;
            height: auto;
            margin-bottom: 18px;
        }
        color: $color;
        font-size: 14px;
    }
}

.ranking-s2 {
    @extend .clearfix;
    margin-bottom: 100px;
    .pics {
        width: 56.81%;
        img:first-child {
            margin-right: 15px;
        }
    }
    .text {
        width: 34.54%;
    }
}

.ranking-s3 {
    @extend .clearfix;
    margin-bottom: 40px;
    .pic {
        width: 55.68%;
    }
}

.ranking-s4 {
    @extend .clearfix;
    margin-bottom: 40px;
    .text {
        width: 55.68%;
        margin-top: 30px;
    }
    .pic {
        width: 35.22%;
    }
}

.ranking-s5 {
    img {
        margin-right: 40px;
    }
}

.content {
    .fl {
        float: left;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .fr {
        float: right;
        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.res-item {
    @extend .clearfix;
    margin-bottom: 100px;
}

.res-item1 {
    img {
        max-width: 31%;
    }
    .text {
        max-width: 64.44%;
        margin-top: 50px;
    }
}

.res-item2 {
    .fl {
        max-width: 53.33%;
    }
    .fr {
        max-width: 28.88%;
    }
}

.res-item3 {
    .fl {
        max-width: 37.77%;
    }
    .fr {
        max-width: 37.77%;
    }
}

.res-item4 {
    .fl {
        max-width: 480px;
    }
}

.res-item2,.res-item3,.res-item4 {
    img {
        margin-bottom: 50px;
    }
}


// Index
.index-banner {
    .swiper-slide {
        height: 560px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .swiper-pagination-bullets {
        bottom: 50px;
        .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
            margin: 0 10px;
        }
    }
}

.index-main {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.index-slogan {
    height: 120px;
    line-height: 120px;
    font-size: 36px;
    font-weight: bold;
    color: #332518;
    text-align: center;
    span {
        color: $color;
    }
    &.slogan2 {
        color: #fff;
        background-color: $color;
        span {
            color: #332518;
        }
    }
}

.section-one {
    @extend .clearfix;
    margin-bottom: 20px;
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    .item {
        width: 100%;
        height: 350px;
        color: #fff;
        font-size: 18px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        h4 {
            position: relative;
            margin-bottom: 19px;
            padding-bottom: 22px;
            color: #fff;
            font-size: 30px;
            font-weight: bold;
            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                content: '';
                width: 100px;
                height: 1px;
                background-color: #fff;
            }
        }
        p {
            max-width: 490px;
        }
    }
    .more {
        display: block;
        margin-top: 30px;
        color: #fff;
        &:hover {
            text-decoration: underline;
        }
    }
    .item1 {
        height: 720px;
        padding: 200px 15px 0;
        text-align: center;
        h4:after {
            left: 50%;
            margin-left: -50px;
        }
        p {
            max-width: 437px;
            margin: 0 auto;
        }
    }
    .item2 {
        margin-bottom: 20px;
        padding: 58px 70px 0;
        background-size: auto;
        background-color: $color;
        background-position: right bottom;
    }
    .item3 {
        padding: 58px 70px 0;
    }
}

.section-three {
    @extend .clearfix;
    margin-top: 20px;
    margin-bottom: 20px;
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    .item {
        position: relative;
        width: 100%;
        height: 465px;
        padding: 100px 15px 0;
        color: #fff;
        font-size: 18px;
        text-align: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        &:hover {
            &:after,.item-content {
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(68, 44, 30, 0.7);
            opacity: 0;
            filter: alpha(opacity=0);
            transition: .3s;
        }
        .item-content {
            position: relative;
            z-index: 2;
            opacity: 0;
            filter: alpha(opacity=0);
            transition: .3s;
        }
        h4 {
            margin-bottom: 75px;
            color: #fff;
            font-size: 30px;
            font-weight: bold;
            line-height: 1;
            .en {
                display: block;
                margin-top: 19px;
            }
        }
        h5 {
            margin-bottom: 80px;
            color: #fff;
            font-size: 18px;
        }
        p {
            max-width: 490px;
        }
    }
    .more {
        display: block;
        margin-top: 30px;
        color: #fff;
        &:hover {
            color: $color;
        }
    }
    .item1 {
        height: 950px;
        .item-content:first-child {
            position: relative;
            margin-bottom: 79px;
            padding-bottom: 79px;
            &:after {
                position: absolute;
                left: 50%;
                bottom: 0;
                content: '';
                width: 300px;
                height: 1px;
                margin-left: -150px;
                background-color: #fff;
            }
        }
    }
}

@media (min-width: 769px) {
    .s3-list {
        margin: 0 -10px;
        li {
            float: left;
            width: 33.33%;
            padding: 10px;
            &:first-child,&:nth-child(4) {
                width: 50%;
            }
            &:nth-child(2),&:nth-child(3) {
                width: 25%;
            }
        }
    }
}

.section-four {
    padding: 100px 15px;
    color: #000;
    text-align: center;
    font-size: 20px;
    background: #fff url(../images/index/s4-bg.jpg) center center no-repeat;
    h3 {
        position: relative;
        margin-bottom: 30px;
        padding-bottom: 30px;
        color: $color;
        font-size: 36px;
        font-weight: bold;
        &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            content: '';
            width: 100px;
            height: 1px;
            margin-left: -50px;
            background-color: $color;
        }
    }
    p {
        text-align: left;
    }
    .name {
        font-size: 24px;
        text-align: right;
    }
}

.section-five, .section-six {
    background-color: #121a21;
}

#store-banner {
    .swiper-slide {
        position: relative;
        height: 600px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #121a21;
    }
    .swiper-pagination-bullets {
        bottom: 50px;
        .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
            margin: 0 10px;
        }
    }
}

.box-address {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    max-width: 100%;
    width: 460px;
    height: 200px;
    margin-top: -100px;
    margin-left: -230px;
    padding-top: 57px;
    color: #fff;
    font-size: 20px;
    border: 4px solid #fff;
    text-align: center;
    h5 {
        color: #fff;
        font-size: 30px;
        font-weight: bold;
    }
    p {
        position: relative;
        margin-bottom: 0;
        padding-bottom: 20px;
        &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            content: '';
            width: 100px;
            height: 1px;
            margin-left: -50px;
            background-color: #fff;
        }
    }
}

.index-news {
    @extend .clearfix;
    margin: 0 -15px;
    padding: 36px 0 40px;
    background-color: #121a21;
    li {
        float: left;
        width: 20%;
        padding: 0 15px;
    }
    .item {
        position: relative;
        display: block;
        height: 250px;
        color: #fff;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #121a21;
        &:hover {
            .item-content,&:after {
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
        &:after {
            opacity: 0;
            filter: alpha(opacity=0);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            content: '';
            width: 100%;
            height: 100%;
            background: rgba(161, 127, 108, 0.9);
            transition: .3s;
        }
    }
    .item-content {
        opacity: 0;
        filter: alpha(opacity=0);
        position: relative;
        z-index: 3;
        padding: 40px 20px 0;
        transition: .3s;
        &:after {
            position: absolute;
            left: 50%;
            top: 120px;
            content: '';
            width: 100px;
            height: 1px;
            margin-left: -50px;
            background-color: #fff;
        }
    }
    .title {
        @include text-overflow(2);
        position: relative;
        margin-bottom: 40px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
    p {
        @include text-overflow(3);
    }
}


// Product Article
.breadcrumb {
    margin-bottom: 20px;
    padding: 0;
    background: none;
    font-size: 14px;
    &>.active {
        color: $color;
    }
}
.product-head {
    @extend .clearfix;
    margin-bottom: 60px;
}
.product-slider {
    float: left;
    width: 61%;
    .swiper-slide {
        height: 400px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .swiper-pagination-bullets {
        bottom: 30px;
        .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
            margin: 0 10px;
        }
        .swiper-pagination-bullet-active {
            background: $color;
        }
    }
    .swiper-button-prev {
        left: 20px;
        background-image: url(../images/icon-left.png);
    }
    .swiper-button-next {
        right: 20px;
        background-image: url(../images/icon-right.png);
    }
}
.product-intro {
    position: relative;
    float: right;
    width: 34.7%;
    height: 400px;
    .head-title {
        margin-bottom: 25px;
    }
    .product-desc {
        height: 175px;
        overflow: hidden;
    }
    .btn-buy {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        padding: 8px 12px;
    }
}

.product-title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
}

.color-list {
    @extend .clearfix;
    position: relative;
    margin-bottom: 40px;
    .tab-content {
        min-height: 84px;
    }
    .item {
        float: left;
        margin-right: 30px;
        &.active {
            margin-bottom: 215px;
            .value {
                position: relative;
                width: 30px;
                height: 30px;
                .arrow {
                    display: block;
                }
            }
            .item-box {
                display: block;
            }
        }
    }
    .value {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .arrow {
        display: none;
        position: absolute;
        z-index: 2;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        bottom: -21px;
        left: 50%;
        margin-left: -11px;
        border-bottom-color: $color;
        border-width: 11px ;
        border-top-width: 0;
        &:after {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            content: "";
            border-width: 10px;
            top: 1px;
            margin-left: -10px;
            content: " ";
            border-bottom-color: #fff;
            border-top-width: 0;
        }
    }
    .item-box {
        display: none;
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        margin-top: 10px;
        padding: 25px 30px;
        border: 1px solid $color;
        text-align: center;
    }
    .btn-close {
        padding-right: 20px;
        color: $color;
        font-size: 14px;
        text-align: center;
        background: url(../images/arrow-up.png) right center no-repeat;
    }
}

.color-nav {
    margin-left: -10px;
    margin-bottom: 10px;
    text-align: left;
    li {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: middle;
        height: 20px;
        line-height: 20px;
        border-right: 1px solid $color;
        &:last-child {
            border-right: none;
        }
        &.active {
            &>a {
                color: $color;
            }
        }
        &>a {
            padding: 0 10px;
            color: #777;
        }
    }
}

.cloth-list {
    @extend .clearfix;
    li {
        float: left;
        margin-right: 20px;
        text-align: center;
        img {
            width: 60px;
            height: 60px;
        }
        p {
            margin-top: 5px;
            margin-bottom: 0;
            font-size: 12px;
            color: #000;
        }
    }
}

.parameter-list {
    @extend .clearfix;
    margin: 0 -15px;
    li {
        float: left;
        width: 25%;
        margin-bottom: 20px;
        padding: 0 15px;
        a {
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 78%;
            overflow: hidden;
            border: 1px solid $color;
            border: 1px solid rgba(161, 127, 108, 0.1);
        }
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

.view-more {
    margin-top: 60px;
    text-align: center;
    .btn {
        width: 240px;
        height: 40px;
        line-height: 26px;
        border-radius: 2px;
        font-size: 18px;
    }
}

.related-category {
    margin-top: 40px;
    text-align: center;
    color: #000;
    font-size: 16px;
    a {
        margin-left: 40px;
        &.active {
            color: $color;
        }
    }
}


// About
.about {
    p {
        margin-bottom: 0;
        text-align: justify;
    }
}
.color-black {
    color: #000;
}
.about-video {
    margin-bottom: 100px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb100 {
    margin-bottom: 100px;
}

.about-s2 {
    @extend .clearfix;
    .s2-cont {
        position: relative;
        float: right;
        margin-left: 160px;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .s2-box {
        position: absolute;
        left: 0;
        top: 50%;
        max-width: 360px;
        margin-top: -200px;
        margin-left: -160px;
        padding: 50px 36px;
        color: #fff;
        background-color: $color;
        .head-title {
            &:before,&:after {
                background-color: #fff;
            }
            h2 {
                color: #fff;
            }
            h4 {
                color: #fff;
            }
        }
    }
}

.about-s3 {
    .box-title {
        margin-bottom: 50px;
    }
}

.about-s4 {
    @extend .clearfix;
    margin-bottom: 30px;
    .fl {
        width: 38.88%;
    }
    .fr {
        width: 51.13%;
    }
}

.about-s5 {
    ul {
        @extend .clearfix;
        margin: 0 -15px;
    }
    li {
        float: left;
        width: 33.33%;
        margin-bottom: 20px;
        padding: 0 15px;
        img {
            width: 100%;
            height: auto;
        }
        p {
            margin-top: 10px;
            margin-bottom: 0;
            font-size: 14px;
        }
    }
}

.about-s6 {
    @extend .clearfix;
    .fl {
        width: 33%;
        margin-top: 100px;
    }
    .fr {
        width: 60%;
        p {
            margin-top: 38px;
            margin-bottom: 0;
        }
    }
}

.about-s7 {
    .pic {
        margin-bottom: 40px;
        text-align: center;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .text {
        @extend .clearfix;
    }
    .fl {
        width: 46.66%;
    }
    .fr {
        width: 46.66%;
    }
}

.about-s8 {
    margin-bottom: 40px;
    .fl {
        width: 47.78%;
    }
    .fr {
        float: left;
        margin-top: 58px;
        margin-left: 100px;
    }
}

.about-s9 {
    .fl {
        width: 56.66%;
    }
    .fr {
        width: 36.36%;
    }
}

.about-s10 {
    @extend .clearfix;
    position: relative;
    margin-bottom: 65px;
    .img {
        width: 65.55%;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .intro {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 31.55%;
        font-size: 14px;
    }
}

.app-footer {
    display: none;
    @extend .clearfix;
    text-align: center;
    .footer-logo {
        width: 50%;
        padding: 0 10px;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .footer-contact {
        float: left;
        width: 50%;
        padding: 0 10px;
        text-align: left;
        border-left: 1px solid rgba(161, 127, 108, 0.3);
        .code {
            color: #000;
            font-weight: bold;
        }
        .tel {
            margin-top: 5px;
            padding-left: 20px;
            font-size: 16px;
            font-weight: bold;
            background-size: 15px auto;
        }
    }
}

.phone-menu {
    display: none;
    position: absolute;
    left: 15px;
    top: 0;
    z-index: 2;
    width: 40px;
    height: 30px;
    margin: 20px 0 0;
    @include img2x('../images/icon-menu',png,20px,20px)
    text-indent: -9999px;
    overflow: hidden;
}

.video-modal .modal-content>[class*=modal-] {
    padding: 0;
}

.embed-responsive {
    width: 100%;
    height: 100%;
    padding-bottom: 56.25%;
}

@media (min-width: 1600px) {
.store {
    .container {
        width: 1560px;
    }
    .store-slide {
        height: 500px;
    }
    .store-intro {
        padding-left: 40px;
    }
}
}


@media (min-width: 1200px) {
.index-banner {
    .swiper-button-prev {
        left: 50%;
        margin-left: -590px;
    }
    .swiper-button-next {
        right: 50%;
        left: auto;
        margin-right: -590px;
    }
}
}

@media (max-width: 1200px) {
.about-s8 {
    .fr {
        margin-top: 12px;
        margin-left: 90px;
    }
}
.honor-head .img {
    margin-right: 60px;
}
.public3 .pic1 {
    margin-right: 30px;
}
.ranking-s2 {
    .text {
        width: 40%;
    }
    .pics {
        width: 57%;
        img {
            width: 45%;
            &:first-child {
                margin-right: 10px;
            }
        }
    }
}
.ranking-s3 {
    .pic {
        width: 53%;
    }
}
.footer-link {
    margin-left: 40px;
}
}

@media (max-width: 992px) {
.navbar.navbar-theme {
    .navbar-nav {
        &>li {
            padding: 0 10px;
        }
    }
}

.store-item {
    margin-bottom: 50px;
}

.store-slide {
    float: none;
    width: 100%;
}

.store-intro {
    float: none;
    width: 100%;
    padding-left: 0;
    .intro-head {
        padding-bottom: 20px;
    }
    .address-qr {
        float: none;
        margin: 0 auto;
    }
}

.sidebar {
    display: none;
}
}

@media (max-width: 768px) {
.nav-top {
    .top-list {
        &>li {
            margin: 0 6px;
        }
    }
}

#weixin-qr {
    .wx-qr {
        left: auto;
        right: 0;
        margin-left: 0;
    }
}

.stock-code {
    font-size: 14px;
    span {
        font-size: 16px;
        line-height: 38px;
    }
}

.phone-menu {
    display: block;
}

.navbar-brand.logo {
    width: 100%;
    height: 30px;
    background-position: center center;
    background-size: auto 30px;
}

.navbar.navbar-theme {
    min-height: 60px;
    border-bottom: 1px solid #d2d2d2;
    .navbar-nav {
        margin: 10px 0;
        &>li {
            padding: 0;
            &:hover {
                .sub-wrap {
                    display: none;
                }
            }
            &.has-sub {
                background-image: url(../images/menu-arrow.png);
                background-position: right 15px top 10px;
                background-repeat: no-repeat;
                background-size: auto 20px;
                &.menu-open {
                    background-image: url(../images/menu-arrow-t.png);
                    background-position: right 15px top 15px;
                    background-size: 20px auto;
                    .sub-wrap {
                        display: block;
                    }
                }
            }
            &.active {
                a {
                    color: $color;
                }
            }
            &>a {
                position: relative;
                height: 40px;
                line-height: 40px;
                padding: 0 15px;
                font-size: 16px;
                text-align: left;
                &:after {
                    display: none;
                }
                &:hover {
                    color: #000;
                }
            }
        }
    }
    .navbar-header {
        height: 60px;
    }
    .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
    }
}

.sub-wrap {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    padding: 20px 0;
    background-color: #f8f8fa;
    box-shadow: 0px 1px 0px 0px rgba(161, 127, 108, 0.2),inset 0px 1px 0px 0px rgba(161, 127, 108, 0.2);
    &.store-sub {
        width: 100%;
    }
}

.open {
    .sub-bg {
        display: none;
    }
}

.sub-menu {
    li {
        float: none;
        margin-right: 0;
        margin-bottom: 0;
        a {
            display: block;
            width: 100%;
            padding: 5px 15px;
            color: #787878;
            font-size: 14px;
        }
    }
}

.store-sub {
    .sub-menu li {
        margin-right: 0;
        a {
            width: auto;
        }
    }
}

.page-wrap {
    padding: 30px 0 40px;
}
.top-list {
    &>li:nth-child(1),&>li:nth-child(2) {
        display: none;
    }
}

.head-title {
    margin-bottom: 30px;
    padding: 37px 0 15px 0;
    h2 {
        margin: 0 0 15px;
        font-size: 22px;
    }
    h4 {
        font-size: 14px;
    }
    &.right {
        text-align: left;
        &:before,&:after {
            left: 0;
            right: auto;
        }
    }
}

.team-list {
    .item {
        margin-bottom: 30px;
        .avt {
            width: 29%;
            height: 130px;
            overflow: hidden;
            margin-right: 20px;
        }
        .intro {
            float: none;
            width: 100%;
        }
    }
    .item1 {
        margin-bottom: 50px;
        .avt {
            float: none;
            width: 100%;
            height: 200px;
            margin-right: 0;
            margin-bottom: 15px;
            overflow: hidden;
            img {
                width: 60%;
                height: auto;
            }
        }
        .intro {
            width: 100%;
        }
        .intro-head {
            text-align: center;
            &:after {
                display: none;
            }
            h5 {
                margin-bottom: 0;
            }
        }
        .video-link {
            text-align: center;
        }
    }
    .intro-head {
        margin-bottom: 20px;
        overflow: hidden;
        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100px;
            height: 1px;
            background: #000;
        }
        .name {
            margin-bottom: 10px;
            color: $color;
            font-size: 22px;
            line-height: 1;
        }
        h5 {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 1.3;
        }
    }
    .item-right {
        text-align: left;
        .avt {
            float: left;
        }
        .intro {
            float: none;
        }
        .intro-head:after {
            left: 0;
            right: auto;
        }
    }
}

.design-pics {
    margin: 20px -3px 0;
    li {
        float: left;
        width: 25%;
        margin-bottom: 6px;
        padding: 0 3px;
    }
}

.footer {
    padding-top: 20px;
    background-color: #fff;
    border-top: 1px solid rgba(161, 127, 108, 0.3);
    .copyright {
        margin-top: 20px;
        border-top: 1px solid rgba(161, 127, 108, 0.3);
    }
}

.app-footer {
    display: block;
}


.contact-method {
    margin-bottom: 30px;
    .col-md-4 {
        margin-bottom: 40px;
    }
    .icon-pic {
        margin-bottom: 30px;
    }
    h5 {
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 1;
    }
    p {
        font-size: 16px;
    }
}

.team-pics {
    margin-bottom: 50px;
}

.contact {
    .page-nav {
        margin-bottom: 30px;
        padding-top: 0;
        &:before {
            display: none;
        }
        li {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            vertical-align: middle;
            margin-right: 8px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .content {
        font-size: 14px;
        line-height: 1.8;
        h5 {
            font-size: 16px;
        }
    }
    .recruit-footer {
        margin-top: 40px;
        p {
            line-height: 1.6;
        }
    }
    .sidebar {
        display: block;
    }
}

.store {
    padding-top: 40px;
}

.contact-team {
    padding: 0 35px;
}

.store-item {
    margin-bottom: 40px;
}

.store-slide {
    padding: 0;
    .swiper-button-prev, .swiper-button-next {
        bottom: 0;
        width: 30px;
        height: 50px;
        background-size: 40%;
    }
}

.store-slide {
    height: 220px;
    margin-bottom: 20px;
    .gallery-thumbs {
        margin: 10px 0;
        .swiper-wrapper{
            margin-left: -25%;
        }
    }
    .gallery-thumbs .swiper-slide {
        width: 50%;
    }
}

.store-intro {
    .intro-head {
        font-size: 16px;
        h2 {
            line-height: 1.5;
            font-size: 24px;
        }
        li {
            margin-right: 0;
        }
    }
}

.product-list {
    margin: 0 -5px;
    .item {
        width: 50%;
        margin-bottom: 30px;
        padding: 0 5px;
        &:hover {
            .more {
                opacity: 0;
                filter: alpha(opacity=0);
            }
        }
    }
    .pic {
        margin-bottom: 20px;
    }
    .title {
        font-size: 13px;
    }
    .series {
        font-size: 16px;
    }
}

.page-navi {
    .arrow-l,.arrow-r {
        width: 26px;
        height: 26px;
        background-size: 100%;
    }
    li {
        &.active {
            a {
                color: $color;
            }
        }
        a {
            padding: 5px 6px;
            font-size: 14px;
        }
    }
}


// product
.product-article {
    background: #f8f8fa;
    .container {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
    }
    .breadcrumb,.product-intro {
        padding: 0 10px;
    }
    .product-head {
        background: #fff;
    }
}
.product-head {
    margin-bottom: 20px;
    padding-bottom: 30px;
    background: #fff;
    box-shadow: 0px 1px 1px rgba(161, 127, 108, 0.2);
}
 .color,.parameter {
    margin-bottom: 20px;
    padding: 20px 10px;
    padding-bottom: 30px;
    background: #fff;
    box-shadow: 0px 1px 1px rgba(161, 127, 108, 0.2);
}
.product-slider {
    float: none;
    width: 100%;
    margin-bottom: 20px;
    .swiper-slide {
        height: 0;
        padding-bottom: 55%;
        overflow: hidden;
    }
    .swiper-pagination-bullets {
        bottom: 10px;
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin: 0 6px;
        }
    }
    .swiper-button-prev,.swiper-button-next {
        background-size: 60%;
    }
    .swiper-button-prev {
        left: 10px;
    }
    .swiper-button-next {
        right: 10px;
    }
}
.product-intro {
    position: relative;
    float: none;
    width: 100%;
    height: auto;
    .head-title {
        margin-bottom: 25px;
        padding-top: 0;
        &:before {
            display: none;
        }
    }
    .product-desc {
        height: auto;
    }
    .btn-buy {
        position: relative;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        padding: 8px 12px;
    }
}

.product-title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
}

.color-list {
    position: relative;
    margin-bottom: 0;
    .item {
        float: left;
        margin-right: 15px;
        &.active {
            margin-bottom: 215px;
            .value {
                position: relative;
                width: 30px;
                height: 30px;
                .arrow {
                    display: block;
                }
            }
            .item-box {
                display: block;
            }
        }
    }
    .value {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
    .arrow {
        margin-left: -8px;
        border-width: 8px;
        &:after {
            border-color: transparent;
        }
    }
    .item-box {
        display: none;
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        margin-top: 10px;
        padding: 20px 0;
        border: none;
        border-top: 1px solid rgba(161,127,108,0.3);
        text-align: center;
    }
    .btn-close {
        display: none;
    }
}

.color-nav {
    margin-left: 0;
    margin-bottom: 15px;
    text-align: left;
    li {
        height: auto;
        line-height: 20px;
        margin-right: 6px;
        border-right: none;
        &:last-child {
            margin-right: 0;
            border-right: none;
        }
        &.active {
            &>a {
                color: #fff;
                background-color: $color;
            }
        }
        &>a {
            display: block;
            min-width: 80px;
            padding: 5px 10px;
            font-size: 14px;
            color: $color;
            background-color: #fff;
            border: 1px solid $color;
            border-radius: 3px;
            text-align: center;
        }
    }
}

.cloth-list {
    li {
        float: left;
        margin-right: 10px;
        text-align: center;
        img {
            width: 50px;
            height: 50px;
        }
    }
}

.parameter-list {
    margin: 0;
    li {
        float: left;
        width: 50%;
        margin-bottom: 10px;
        padding: 0 5px;
    }
}

.view-more, .related-category {
    display: none;
}

.honor-head {
    margin-bottom: 30px;
    .img {
        float: none;
        margin-right: 0;
        margin-bottom: 30px;
        text-align: center;
    }
}

.box-title {
    max-width: 310px;
    max-height: 310px;
    margin-left: auto;
    margin-right: auto;
    padding: 137px 34px 34px;
    border: 4px solid $color;
}

// About
.about-video {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 20px;
}

.mb100 {
    margin-bottom: 30px;
}

.content {
    .fl {
        float: none;
        width: 100%!important;
        margin-bottom: 20px;
    }
    .fr {
        float: none;
        width: 100%!important;
    }
}

.about-s2 {
    .s2-cont {
        position: relative;
        float: none;
        margin-left: 0;
    }
    .s2-box {
        position: relative;
        left: 0;
        top: 0;
        max-width: 360px;
        margin-top: 0;
        margin-left: 0;
        padding: 30px 20px;
    }
}

.about-s3 {
    .box-title {
        margin-bottom: 30px;
    }
}

.about-s4 {
    margin-bottom: 30px;
}

.about-s5 {
    width: 100%;
    overflow: hidden;
    ul {
        margin: 0 -4px;
    }
    li {
        float: left;
        width: 33.33%;
        margin-bottom: 10px;
        padding: 0 4px;
        img {
            width: 100%;
            height: auto;
        }
        p {
            height: 38px;
            margin-top: 10px;
            margin-bottom: 0;
            font-size: 12px;
        }
    }
}

.about-s6 {
    .fl {
        margin-top: 0;
    }
    .fr {
        p {
            margin-top: 38px;
            margin-bottom: 0;
        }
    }
}

.about-s7 {
    .pic {
        margin-bottom: 40px;
        text-align: center;
    }
    .fl {
        width: 46.66%;
    }
    .fr {
        width: 46.66%;
    }
}

.about-s8 {
    margin-bottom: 40px;
    .fr {
        margin-top: 30px;
        margin-left: 0;
    }
}

.about-s9 {
    .fl {
        p {
            margin-bottom: 20px;
        }
    }
    .fr {
        text-align: center;
    }
}

.about-s10 {
    margin-bottom: 50px;
    .img {
        width: 100%;
        margin-bottom: 20px;
    }
    .intro {
        position: relative;
        width: 100%;
    }
}


// Public
.public-banner {
    margin-bottom: 30px;
}

.public-content {
    .img1 {
        margin-bottom: 20px;
    }
}

.public-msci {
    margin-bottom: 40px;
    .pics {
        margin-bottom: 20px;
        text-align: center;
        img:last-child {
            float: none;
            margin-top: 20px;
        }
    }
}

.public3 {
    .pics {
        margin-bottom: 20px;
        text-align: center;
    }
    .pic1 {
        float: none;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .pic2 {
        float: none;
        width: 100%;
        margin-bottom: 20px;
    }
    .intro {
        float: none;
        width: 100%;
    }
}

.ranking-s2 {
    margin-bottom: 30px;
    .pics {
        img {
            float: left;
            width: 48%;
        }
        img:first-child {
            margin-right: 4%;
        }
    }
    .text {
        max-width: 100%;
    }
}

.ranking-s3 {
    margin-bottom: 30px;
}

.ranking-s4 {
    margin-bottom: 30px;
    .text {
        max-width: 490px;
        margin-top: 0;
    }
    .pic {
        text-align: center;
    }
}

.ranking-s5 {
    .fl {
        float: left;
        width: 150px!important;
        margin-right: 10px;
    }
    img {
        margin-right: 0;
    }
    p {
        text-align: justify;
    }
}

.honor-pics {
    margin: 0 -5px;
    li {
        width: 50%;
        margin-bottom: 20px;
        padding: 0 5px;
        img {
            padding: 6px;
            border: 2px solid $color;
        }
    }
}

.res-item {
    margin-bottom: 20px;
}

.res-item1 {
    img.fl {
        max-width: 100%;
        width: auto!important;
    }
    .text {
        max-width: 100%;
        margin-top: 0;
    }
}

.res-item2 {
    .fl {
        max-width: 100%;
    }
    .fr {
        max-width: 100%;
    }
}

.res-item3 {
    .fl {
        max-width: 100%;
    }
    .fr {
        max-width: 100%;
    }
}

.res-item2,.res-item3 {
    img {
        margin-bottom: 20px;
    }
}

.news-list {
    margin: 0;
    .item {
        float: none;
        width: 100%;
        margin-bottom: 30px;
        padding: 0;
    }
    .time {
        margin-bottom: 10px;
        font-size: 15px;
        line-height: 1;
    }
    .pic {
        height: 0;
        margin-bottom: 20px;
        padding-bottom: 48%;
    }
    h4 {
        font-size: 20px;
    }
}


// Index
.index-banner {
    .swiper-slide {
        height: 0;
        padding-bottom: 51%;
        overflow: hidden;
    }
    .swiper-pagination-bullets {
        bottom: 10px;
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin: 0 5px;
        }
    }
    .swiper-button-next, .swiper-button-prev {
        background-size: 50%;
    }
}

.index-main {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.index-slogan {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
}

.section-one {
    .item {
        width: 100%;
        height: auto;
        font-size: 16px;
        h4 {
            position: relative;
            margin-bottom: 19px;
            padding-bottom: 22px;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
        }
        p {
            max-width: 490px;
        }
    }
    .item1 {
        height: auto;
        margin-bottom: 10px;
        padding: 60px 15px 60px;
    }
    .item2 {
        margin-bottom: 10px;
        padding: 30px 15px;
        background-size: auto;
        background-color: $color;
        background-position: right bottom;
    }
    .item3 {
        padding: 30px 15px;
    }
}

.section-three {
    margin-top: 0;
    margin-bottom: 0;
    .mt20 {
        margin-top: 0;
    }
    .item {
        position: relative;
        width: 100%;
        height: 212px;
        padding: 40px 5px;
        font-size: 16px;
        &:after,.item-content {
            opacity: 1;
            filter: alpha(opacity=100);
        }
        h4 {
            margin-bottom: 20px;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            line-height: 1;
            .en {
                display: block;
                margin-top: 15px;
            }
        }
        h5 {
            margin-bottom: 30px;
            color: #fff;
            font-size: 12px;
        }
        p {
            max-width: 490px;
        }
    }
    .more {
        margin-top: 20px;
        font-size: 14px;
    }
    .item1 {
        height: auto;
        .item-content:first-child {
            position: relative;
            margin-bottom: 40px;
            padding-bottom: 40px;
            &:after {
                position: absolute;
                left: 50%;
                bottom: 0;
                content: '';
                width: 300px;
                height: 1px;
                margin-left: -150px;
                background-color: #fff;
            }
        }
    }
    .item4 {
        margin-top: 0;
    }
}

.s3-list {
    margin: 0 -5px;
    li {
        float: left;
        width: 50%;
        padding: 5px;
        &:first-child {
            width: 100%;
        }
    }
}

.section-four {
    padding: 30px 0;
    font-size: 16px;
    h3 {
        margin-bottom: 30px;
        padding-bottom: 30px;
        font-size: 24px;
    }
    .name {
        font-size: 18px;
    }
}

#store-banner {
    .swiper-slide {
        height: 240px;
    }
    .swiper-pagination-bullets {
        bottom: 10px;
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin: 0 5px;
        }
    }
}

.box-address {
    width: 80%;
    height: auto;
    margin-top: -80px;
    margin-left: -40%;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 16px;
    border: 2px solid #fff;
    h5 {
        font-size: 22px;
    }
}

.index-news {
    margin: 0 -2px;
    padding: 4px;
    background-color: #121a21;
    li {
        float: left;
        width: 25%;
        padding: 0 2px;
        &:nth-child(5) {
            display: none;
        }
    }
    .item {
        height: 0;
        padding-bottom: 73.33%;
        &:hover {
            .item-content,&:after {
                display: none;
            }
        }
    }
    .item-content {
        display: none;
    }
}


// News Article
.news-title {
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 15px;
    h2 {
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 1.4;
    }
    .time {
        font-size: 14px;
    }
}

.news-content {
    .img-intro {
        display: block;
        margin-top: 20px;
        padding-bottom: 0;
        color: #a1a1a1;
        font-size: 14px;
        line-height: 1.4;
    }
    p {
        margin-bottom: 20px;
    }
}

.multi-bg {
    padding: 40px 0 40px;
}

}


// English
html[lang=en] {
    .sub-menu {
        li {
            margin-right: 22px;
            a {
                width: auto;
            }
        }
    }
    .footer-link {
        max-width: 220px;
        margin-left: 30px;
    }
    &:last-child .footer-link {
        max-width: 160px;
    }
    .footer .footer-qrcode p {
        font-size: 12px;
    }
    .page-wrap {
        .head-title {
            h4 {
                @include text-overflow(2);
                font-size: 15px;
                line-height: 1.3;
            }
        }
    }
    .public3 .pic1 {
        width: 32%;
        margin-right: 3%;
    }
    .about-s8 {
        .fr {
            width: 42%;
            margin-left: 9%;
        }
    }
    .about-s10 {
        .intro {
            font-size: 12px;
        }
    }
}

@media (max-width: 1200px) {
    .about-s8 {
        .fr {
            width: 42%;
            margin-top: 0;
            margin-left: 9%;
        }
    }
}

@media (max-width: 768px) {
    html[lang=en] {
        .sub-menu {
            li {
                margin-right: 0;
            }
        }
        .page-wrap {
            .head-title {
                h4 {
                    @include text-overflow(4);
                    font-size: 14px;
                    line-height: 1.3;
                }
            }
        }
        .public3 .pic1 {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}

@media (min-width: 768px) {
    .navbar>.container .navbar-brand {
        margin-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .footer {
        .container {
            width: 960px;
        }
    }
}